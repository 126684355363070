var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marqueeData
    ? _c(
        "div",
        { staticClass: "mb-4 background" },
        [
          _c(
            "MarqueeText",
            { attrs: { duration: _vm.marqueeData.length * 4 } },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_vm.marqueeData, function(user, index) {
                  return _c(
                    "div",
                    {
                      key: user.account + index,
                      staticClass: "user my-2 mx-8 d-flex align-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "img" },
                        [
                          _c(
                            "v-avatar",
                            {
                              style: { border: "2px solid " + _vm.primaryClr }
                            },
                            [
                              _c("img", {
                                staticClass: "h-100 w-100 object-fit",
                                attrs: {
                                  src: _vm.baseImgUrl + user.game_image,
                                  alt: user.game_name
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content ml-3 white--text caption" },
                        [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(" " + _vm._s(user.account) + " "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ml-2 primary--text font-weight-bold"
                              },
                              [_vm._v(_vm._s(user.game_name))]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "amount font-weight-bold" },
                            [
                              _c("span", { staticClass: "primary--text" }, [
                                _vm._v(_vm._s(_vm.$t("game.award")))
                              ]),
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currencySymbol_ + user.win_amount
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }